import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyCPMce5_ZV5IG0Kiuok_Lf8LnWiX1C0Ffc",
  authDomain: "bakat-dev.firebaseapp.com",
  projectId: "bakat-dev",
  storageBucket: "bakat-dev.appspot.com",
  messagingSenderId: "87381884606",
  appId: "1:87381884606:web:d0a56892b18668743c68ef"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}


let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  messaging.getToken({vapidKey: "BAq0CpPYp5ixaWFDRwv8cTZopmczAqv3mrqBX3YgW_Q4TtCq-Q5M0Wa1n6zNwsNUfCdnZW3x45E1egDPHMKV5VI"})
}



export default firebase