<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="data.list"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :item-key="'id'"
    class="transparent"
    :mobile-breakpoint="0"
    :server-items-length="data.total"
    loading-text="Loading... Please wait"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }"
  >
    <template v-slot:header.progress="{ header }">
      <th v-if="sortDesc===false" @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
      <th v-else @click="sortCalories" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
    </template>
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white text-capitalize" v-text="item.license"></td>
        <td class="white text-capitalize" v-text="item.member.fullname" style="width: 30%;"></td>
        <td class="white text-capitalize" v-text="item.member.phone"></td>
        <td class="white text-capitalize">{{ item.results.progress }}%</td>
        <td class="white rounded-r-lg" style="width: 5%">
          <v-btn
            text
            color="primary"
            class="font-weight-medium"
            @click="$emit('detail', item)">
            Detail Aktivitas
          </v-btn>
        </td>
      </tr>
    </template>    
  </v-data-table>

</template>
<script>
export default {
  name: "program-table",
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },
  },
  data: () => ({
    sortBy: "progress",
    sortDesc: true,
    headers: [
      { text: "Kode Voucher", class: "text-no-wrap" ,value:"license", sortable: false, },
      { text: "Nama Peserta", class: "text-no-wrap" ,value:"name",sortable: false },
      { text: "Nomor Whatsapp", class: "text-no-wrap" ,value:"phone",sortable: false },
      { text: "Status", class: "text-no-wrap",value:"progress", sortable: false},
      { text: "Aksi", class: "text-no-wrap",sortable: false },
    ],
    item:[
      {
        kode_voucher:'16AE41332TY90',
        nama:'Medina Bella E',
        no_wa:'+62 87848903467',
        status:'90%',
        // pre:80,
        // post:92
      },      
    ],
    table_loading: false,
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  methods: {
    paginate: (current, total) => {
      if (current > total) {
        throw new Error("Current page is larger than total");
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? "..." : i + current - 2;
              case 4:
                return current < total - 2 ? "..." : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? "..." : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? "..." : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
    sortCalories() {
      if (this.sortDesc === false) {
        this.sortDesc = true;
      }else{
        this.sortDesc = false;        
      }
      // console.log('sort',this.sortDesc)
      this.$emit("sorting",this.sortDesc);
    }
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style>
</style>