<template>
  <v-data-table
    :loading="isLoading"
    class="transparent"
    :headers="headers"
    :item-key="'id'"
    :items="data.list"
    :mobile-breakpoint="0"
    loading-text="Loading... Please wait!"
    no-data-text="Silahkan cari data antrean export terlebih dahulu"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :server-items-length="data.total_data"
    :footer-props="{
      'items-per-page-text':'Row per page : '
    }" 
    >
    <!-- <template v-slot:header.total="{ header }">
      <th v-if="sortDesc===false" @click="sortCalories()" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-up-line</v-icon></th>
      <th v-else @click="sortCalories()" style="cursor: pointer;">{{ header.text }} <v-icon small>ri-arrow-down-line</v-icon></th>
    </template> -->
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white rounded-l-lg"> {{ item.name }}</td>
        <td class="white rounded-l-lg"> {{ item.title }}</td>
        <td class="white text-center">
          <v-chip
          :ripple="false"
          class="px-4 fs-14 font-weight-medium"
          :color="item.status == 'completed' ? 'success' : item.status == 'processing' ? 'warning' : 'error'"
          label
          v-text="item.status == 'completed' ? 'Selesai' : item.status == 'processing' ? 'Sedang diproses' : 'Gagal'"
          ></v-chip>
        </td>
        <td class="white rounded-l-lg text-center"> {{ convertCreatedAt(item.created_at) }}</td>
        <td class="white rounded-r-lg text-center" style="width: 15%">
          <div class="d-flex flex-row" style="gap:20px">
            <v-btn 
              title="Downlaod" 
              outlined 
              color="blue" 
              x-small 
              icon
              :disabled="item.status !== 'completed' && item.link !== ''"
              @click="$emit('export-file', item)">
              <v-icon>ri-download-line</v-icon>
            </v-btn>
            <v-btn 
              title="Refresh" 
              outlined 
              color="primary" 
              x-small 
              icon
              @click="$emit('reset-export', item)"
              :disabled="item.status !== 'failed'">
              <v-icon>ri-refresh-line</v-icon>
            </v-btn>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment';

export default {
  name: "program-table",
  props: {
    data: { required: true, type: Object, default: () => ({}) },
    loading: { type: Boolean, default: () => false },

  },
  data: () => ({
    sortBy: "name",
    sortDesc: false,
    headers: [
      { text: "Nama Antrean", class: "text-no-wrap", sortable: false, },
      { text: "Judul Antrean", class: "text-no-wrap", sortable: false, },
      { text: "Status", class: "text-no-wrap", sortable: false, align: "center" },
      { text: "Tanggal Dibuat", class: "text-no-wrap", sortable: false, align: "center" },
      { text: "Aksi", class: "text-no-wrap", sortable: false, },
    ],
    table_loading: false,
    
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
    convertCreatedAt() {
      return (date) => {
        return moment(date).format("DD MMMM YYYY HH:mm");
      };
    }
  },
  methods: {
    sortCalories() {
      console.log('sort');
      // if (this.sortDesc === false) {
      //   this.sortDesc = true;
      // }else{
      //   this.sortDesc = false;        
      // }
      // this.$emit("sorting",this.sortDesc);
    },
  },
  watch: {
    "data.limit": function () {
      this.$emit("refresh-list");
    },
    "data.page": function () {
      this.$emit("refresh-list");
    },
  }
};
</script>
<style>
</style>