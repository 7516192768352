import axios from "axios";
import url from "./api";

const config = { headers: { "content-type": "application/json" } };

const state = () => ({
  data: {},
  params: {},
  dataPublish:{},
  paramsPublish: {},
  dataDraft:{},
  dataArchive:{}
});

const getters = {
  data: (state) => state.data,
  params: (state) => state.params,
  dataPublish: (state) => state.dataPublish,
  paramsPublish: (state) => state.paramsPublish,
  list: (state) => state.data.list || [],
  limit: (state) => state.data.limit || 10,
  page: (state) => state.data.page || 1,
  total: (state) => state.data.total || "0",
  listPublish: (state) => state.dataPublish.list || [],
  limitPublish: (state) => state.dataPublish.limit || 5,
  pagePublish: (state) => state.dataPublish.page || 1,
  totalPublish: (state) => state.dataPublish.total || "0",
  listDraft: (state) => state.dataDraft.list || [],
  limitDraft: (state) => state.dataDraft.limit || 5,
  pageDraft: (state) => state.dataDraft.page || 1,
  totalDraft: (state) => state.dataDraft.total || "0",
  listArchive: (state) => state.dataArchive.list || [],
  limitArchive: (state) => state.dataArchive.limit || 5,
  pageArchive: (state) => state.dataArchive.page || 1,
  totalArchive: (state) => state.dataArchive.total || "0",
  detail: (state) => state.data.detail || {},
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data = { ...state.data, ...data };
  },
  SET_PARAMS(state, data) {
    state.params = data;
  },
  SET_DATA_PUBLISH: (state, dataPublish) => {
    state.dataPublish = { ...state.dataPublish, ...dataPublish };
  },
  SET_PARAMS_PUBLISH(state, dataPublish) {
    state.paramsPublish = dataPublish;
  },
  SET_DATA_DRAFT: (state, dataDraft) => {
    state.dataDraft = { ...state.dataDraft, ...dataDraft };
  },
  SET_DATA_ARCHIVE: (state, dataArchive) => {
    state.dataArchive = { ...state.dataArchive, ...dataArchive };
  },
  SET_DETAIL: (state, data) => {
    state.data = { ...state.data, ...{ detail: data } };
  },
};

const actions = {
  // async list({ commit, getters }, params) {
  async list({ state, commit, getters }, params ) {
    let a ={
        page: params.q != "" && state.data.total < 10 ? 1 : getters.page, 
        limit: getters.limit, 
        sort: 'created_at', 
        sort_type: 'desc'
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params umum', params)
      state.data.list = [];
      return axios.get(url.program_list,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          commit("SET_DATA", res.data);
          return res.data;
        });

    // params = { ...{ limit: getters.limit, page: getters.page, sort: 'created_at', sort_type: 'desc' }, ...params };
    // return axios
    //   .get(url.program_list, { params })
    //   .then((res) => res.data)
    //   .then((res) => {
    //     commit("SET_DATA", res.data);
    //   });
  },
  async publish({ state, commit }, params) {
    // params = { ...{ limit: getters.limitPublish, page: getters.pagePublish , status:'publish' ,sort: 'created_at', sort_type: 'desc' }, ...params };
    let a ={
        page: state.dataPublish.page, limit: state.dataPublish.limit, status:'publish' , sort: 'created_at', sort_type: 'desc'
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params', params)
      state.dataPublish.list = [];
    // return axios
    //   .get(url.program_list, { params })
    //   .then((res) => res.data)
    //   .then((res) => {        
    //     commit("SET_DATA_PUBLISH", res.data);
    //   });
    return axios.get(url.program_list,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS_PUBLISH", params);
          commit("SET_DATA_PUBLISH", res.data);
          return res.data;
        });
  },
  async draft({ commit, getters }, params) {
    params = { ...{ limit: getters.limitDraft, page: getters.pageDraft , status:'draft' ,sort: 'created_at', sort_type: 'desc' }, ...params };
    return axios
      .get(url.program_list, { params })
      .then((res) => res.data)
      .then((res) => {        
        commit("SET_DATA_DRAFT", res.data);
      });
  },
  async archive({ commit, getters }, params) {
    params = { ...{ limit: getters.limitDraft, page: getters.pageDraft , status:'archive' ,sort: 'created_at', sort_type: 'desc' }, ...params };
    return axios
      .get(url.program_list, { params })
      .then((res) => res.data)
      .then((res) => {        
        commit("SET_DATA_ARCHIVE", res.data);
      });
  },    
  async detail({ commit }, id) {
    return axios
      .get(url.program_detail.replace("{id}", id))
      .then((res) => res.data)
      .then((res) => {
        commit("SET_DETAIL", res.data);
      });
  },
  async create({ dispatch }, data) {
    return axios.post(url.program_create, data).then(dispatch("list"));
  },
  async update({ dispatch }, data) {
    data = {
      id: data.id,
      category_id: data.category_id || data.category.id,
      name: data.name,
      available_part: data.available_part,
    };

    return axios
      .post(url.program_update.replace("{id}", data.id), data, config)
      .then(dispatch("list"));
  },
  async delete({ dispatch }, id) {
    return axios
      .delete(url.program_delete.replace("{id}", id))
      .then(dispatch("list"));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
